:root {
  --main-bg-color: #f5f5f5;
  --secondary-bg-color: #e2ecef;
  --accent-color: #D3DFDF;
  --main-text-color: #000;
}

body {
  min-height: 100vh;
  /* display: flex;
  flex-direction: column; */
  background-color: var(--main-bg-color);
}
a {
  color: var(--main-text-color);
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-bg-color);
}
main {
  padding: 20px 0;
}
.hero {
  padding:25px;
  margin:0 0 25px 0;
  border-radius: 20px;
  background-color: var(--accent-color);
}

.records-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}
.record-card {
  width: 30%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.members-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
  margin-top: 20px;
}
.member-card {
  width: 350px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 2px solid black;
}
footer p {
  display: flex;
  align-items: center;
  gap: 10px;
}
footer p a {
  border-right: 1px solid #999;
  padding-right: 10px;
}
footer p a:last-child {
  border-right: none;
  padding-right: 0;
}

@media (max-width: 650px) {
  .record-card {
    width: 350px;
  }
}